import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes';
import {AuthCheck, getStorageToken} from "@/mixins/Auth";
import {setAuthInHeader} from "@/api/request";


const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        if(to.hash != from.hash ) {
            return { x: 0, y: 0 };
        } else {
            return { top: 0 };
        }
    },
    history: createWebHistory(),
    routes : routes,
})

router.beforeEach(function (to, from, next) { // 토큰이 로컬스토리지에 담겼을 시 사용
    // to: 이동할 url에 해당하는 라우팅 객체
    if (to.matched.some(function (routeInfo) {
        return routeInfo.meta.authRequired;
    })) {
        let status = AuthCheck();
        if (status == 1) {
            let token = getStorageToken();
            setAuthInHeader(token)
            next(); // 페이지 전환
        } else {
            //toke 삭제
            next({
                path: '/auth',
                query: {redirect: to.fullPath}
            })
        }
    } else {
        next(); // 페이지 전환
    };
});

export default router;

let isDev = true;
let isBata = true;

let config = {
    dev: {
        apiDomain: "https://testapi.minglemint.com",
        bucketOption: {
            BucketName: 'minglemint-dev-real',
            BucketRegion: 'ap-northeast-2',
            BucketIdentityPoolId: 'ap-northeast-2:76a65f97-bc86-46d0-a35a-16574af89e2a',
        },
        nftBucketOption: {
            BucketName: 'minglemint-dev-real-nft',
            BucketRegion: 'ap-northeast-2',
            BucketIdentityPoolId: 'ap-northeast-2:76a65f97-bc86-46d0-a35a-16574af89e2a',
        },
        imageDomain: "http://testrealimage.minglemint.com",
    },
    prod: {
        apiDomain: "https://api.minglemint.com",
        bucketOption: {
            BucketName: 'minglemint-dev',
            BucketRegion: 'ap-northeast-2',
            BucketIdentityPoolId: 'ap-northeast-2:76a65f97-bc86-46d0-a35a-16574af89e2a',
        },
        nftBucketOption: {
            BucketName: 'minglemint-dev-nft',
            BucketRegion: 'ap-northeast-2',
            BucketIdentityPoolId: 'ap-northeast-2:76a65f97-bc86-46d0-a35a-16574af89e2a',
        },
        imageDomain: "http://image.minglemint.com",
    },
};

let localStorageKey = 'com.dgmonglab.minglepass-vue-admin'

function getConfig(key = 'apiDomain') {
    return isDev ? config.dev[key] : config.prod[key];
}

function setConfig(key, value) {
    if (isDev) {
        config.dev[key] = value;
    } else {
        config.prod[key] = value;
    }
}

function getVersion() {
    console.log('Dev_1_master/1')
}

let apiDomain = getConfig('apiDomain')
let apiURL = `${getConfig('apiDomain')}/api/`;
let bucketOption = getConfig('bucketOption');
let nftBucketOption = getConfig('nftBucketOption');
let imageDomain = getConfig('imageDomain');

let currentVersion = getVersion();

function setterApiDomain(domain) {
    setConfig('apiDomain', domain);
    apiDomain = getConfig('apiDomain');
    apiURL = `${getConfig('apiDomain')}/api/`;
    let devDomain = config.dev.apiDomain;
    let prodDomain = config.prod.apiDomain;
    if (devDomain == domain) {
        isDev = true;
    } else if (prodDomain == domain) {
        isDev = false;
    }
    bucketOption = getConfig('bucketOption');
    nftBucketOption = getConfig('nftBucketOption');
    imageDomain = getConfig('imageDomain');
}


export {
    apiURL,
    apiDomain,
    imageDomain,
    setterApiDomain,
    bucketOption,
    nftBucketOption,
    isBata,
    localStorageKey,
    isDev,
};
